<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title class="headline">
          Edit Subscription for <a target="_blank" :href="'https://dashboard.stripe.com/search?query='+email"> {{email}}</a>
        </v-card-title>
        <v-card-text v-on="error">{{error}}</v-card-text>
        <v-form class="pa-5" v-if="model">
          <v-alert v-if="errorMessage" type="warning">{{errorMessage}}</v-alert>
          <v-checkbox v-model="model.active" :label="`Subscription Enabled`"></v-checkbox>
          <v-text-field
              @click:prepend="previewContact"
              prepend-icon="mdi-account"
              append-outer-icon="mdi-store-search-outline" @click:append-outer="fetchSubData()"
              suffix="search in stripe"
              v-model="model.customer_id"
              label="Stripe Customer Id"
          ></v-text-field>
          <v-text-field
              @click:prepend="previewSub"
              prepend-icon="mdi-autorenew"
              v-model="model.sub_id"
              label="Stripe Subscription Id"
          ></v-text-field>
          <v-text-field
              @click:prepend="previewSub"
              prepend-icon="mdi-autorenew"
              v-model="model.product_id"
              label="Stripe Product Id"
          ></v-text-field>
          <v-text-field
              @click:prepend="previewSub"
              prepend-icon="mdi-autorenew"
              v-model="model.price_id"
              label="Stripe Price Id"
          ></v-text-field>
          <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="model.start_date"
                  label="Subscription Start Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="model.start_date"
                @input="menu = false"
            ></v-date-picker>
          </v-menu>
          <v-text-field
              prepend-icon="mdi-account-group"
              v-model="model.num_of_students"
              label="Number of students"
          ></v-text-field>

          <v-text-field
              prepend-icon="mdi-school"
              v-model="model.num_of_classes"
              label="Number of classes"
          ></v-text-field>
            <v-jsoneditor v-if="advanced" v-model="model" :plus="true" height="500px"></v-jsoneditor>

        </v-form>
        <v-divider></v-divider>
          <v-card-actions>
          <v-btn color="secondary" text @click="advanced=!advanced">{{!advanced ? 'Show' : 'Hide'}} Advanced</v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="darken-1"
              text
              @click="onCancel"
          >
            Cancel
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="onOkAndSave"
          >
            Save and {{model.active ? 'Activate' : 'Disable'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import _ from "lodash";
import VJsoneditor from 'v-jsoneditor'
import {mapActions, mapState} from "vuex";
import {SUB_SERVICE_API, SUB_SERVICE_SECRET} from "@/constants";

const SUB_DEFAULTS = {
  active: false,
  customer_id: '',
  sub_id: '',
  start_date: '',
  price_id: '',
  product_id: '',
  status: '',
  num_of_students: 40,
  num_of_classes: 9999,
}
export default {

  name: "EditSubscriptionDialog",
  components: {
    VJsoneditor
  },
  data() {
    return {
      errorMessage: null,
      user_id: null,
      email: '',
      date: null,
      menu: false,
      advanced: false,
      dialog: false,
      model: {},
      error: ''
    }
  },
  computed: {
    lastSub() {
      return this.model
    },
    ...mapState(['user'])
  },
  methods: {
    ...mapActions(['setGlobalLoading']),
    previewSub() {
      window.open('https://dashboard.stripe.com/subscription/'+this.model.product_id, '_blank');
    },
    previewContact() {
      window.open('https://dashboard.stripe.com/customers/'+this.model.customer_id, '_blank');
    },
    init_ui(email, subObj) {
      this.model = _.defaultsDeep(_.cloneDeep(subObj), SUB_DEFAULTS);
      if (_.isEmpty(this.model.start_date)) this.model.start_date = (new Date(this.lastSub.start_date * 1000).toISOString().substring(0, 10));
      this.advanced = false;
    },
    show(email, user_id, subObj) {
      this.init_ui(email, subObj);
      this.email = email;
      this.user_id = user_id;
      this.dialog = true;
    },
    async fetchSubData() {
      this.loading = true;
      const payload = {
        secret_key: SUB_SERVICE_SECRET,
        user_id: this.user_id
      }
      if (!_.isEmpty(this.model.customer_id)) {
        payload.customer_id = this.model.customer_id
      }
      const res = await fetch(SUB_SERVICE_API+'/subs/find', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }).then(res => res.json())
      this.loading = false;
      if (res.success) {
        this.model = _.defaultsDeep(res.data, SUB_DEFAULTS);
        this.model.start_date = (new Date(this.model.start_date * 1000).toISOString().substring(0, 10))
      } else {
        this.errorMessage = res.message;
      }
    },
    onCancel() {
      this.dialog=false;
    },
    onOk() {
      if (this.validateForm()) {
        this.dialog=false;
        this.email=''
        this.user_id=null;
        this.errorMessage=null
        const subObj = _.cloneDeep(this.model)
        if (!_.isEmpty(subObj.start_date)) {
          subObj.start_date = new Date(subObj.start_date).getTime()/1000
        }
        this.$emit('subscription-updated', _.cloneDeep(subObj))
      }
    },
    async onOkAndSave() {
      if (this.validateForm()) {
        this.setGlobalLoading(true)
        const subObj = _.cloneDeep(this.model)
        if (!_.isEmpty(subObj.start_date)) {
          subObj.start_date = new Date(subObj.start_date).getTime()/1000
        }
        const url =  `${SUB_SERVICE_API}/subs/${subObj.active ? 'activate' : 'disable'}`
        try {
          const payload = {
            user_id: this.user_id,
            secret_key: SUB_SERVICE_SECRET,
            skip_stripe: true,
            customer_id: subObj.customer_id,
            opt_sub_obj: subObj
          }
          const {data, status} = await this.axios.post(url, payload);
          if (status !== 200) {
            this.$root.$emit('notify', {message: `could not update sub`})
          } else {
            this.$root.$emit('notify', {message: `Successfully updated subscription and disabled ads`})
          }
        } catch (e) {
          console.error(e);
          this.$root.$emit('notify', {message: `could not update sub`})
        }
        this.email = ''
        this.user_id = null;
        this.errorMessage = null
        this.dialog = false;
        this.setGlobalLoading(false)
        this.$emit('subscription-updated', _.cloneDeep(subObj))
      }
    },
    validateForm() {
      return true;
    },
    showError(message) {
      this.error = message;
      setTimeout(() => {
        this.error = '';
      }, 3000);
    }
  }
}
</script>

<style scoped>

</style>
