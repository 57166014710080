<template>
    <v-select
            v-model="selected"
            :label="label"
            @input="report"
            :items="items"
            item-text="desc"
            item-value="item"
            :disabled="items && isLoading"
            :hint="hint"
            persistent-hint
            return-object
    >
      <template v-slot:prepend-item>
        <v-list-item ripple>
          <v-list-item-content>
            <v-list-item-title>
              Select from <b>Level {{level}}</b> passages
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
    </v-select>

</template>
<script>

import {mapActions} from "vuex";
import {mapState} from "vuex";
import {find} from "lodash";

export default {
  props: ['level', 'initId', 'modelProp', 'label'],
  data: () => ({
    items: [],
    selected: null,
    isLoading: true,
  }),
  computed: {
    ...mapState(['quiz']),
    quizzes() {
      return this.quiz.quizzes[this.level] || [];
    },
    hint() {
      return this.selected ? `id: ${this.selected.id}, Level: ${this.selected.level}, Title: ${this.selected.title}` : '';
    }
  },
  methods: {
    report() {
      this.$emit('input', this.modelProp ? this.selected[this.modelProp] : this.selected)
    },
    async refresh() {
      this.isLoading = true;
      this.items = await this.fetch_quizzes({level: (this.level ? this.level : 'One')})
      this.items = this.items.map(item => {
        return {
          ...item,
          desc: `${item.title} (id:${item.id})`
        }
      })
      if (this.initId) {
        const tmp = find(this.items, {id: parseInt(this.initId)})
        if (tmp) {
          this.selected = tmp;
        } else if (this.selected) {
          this.items.push(this.selected);
        }
      }
      this.isLoading = false;
    },
    ...mapActions(['fetch_quizzes'])
  },
  mounted() {
    this.refresh();
  },
  watch: {
    level: function() {
      this.refresh();
    }
  },
}
</script>
