<template>
  <div>
  <v-subheader>Student Info
  </v-subheader>
  <v-row class="left">
    <v-col cols="12">
<!--      <v-card elevation="1" class="ma-2">-->
<!--      <v-card-title>Student Info</v-card-title>-->
      <v-skeleton-loader v-if="loading"
          class="'mx-auto pa-3 dark" :boilerplate="false" elevation="0"
          type="list-item-two-line, list-item-three-line, actions"
      ></v-skeleton-loader>
      <v-form v-if="!loading" class="pa-4">
        <v-row class="left">
          <v-col cols="12"
                 sm="6"
                 md="6">
            <v-text-field
                v-model="editItem.id"
                label="Student ID"
                disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12"
                  sm="3"
                  md="3">
            <v-text-field
                v-model="editItem.age"
                label="Age"
                disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="left">
          <v-col cols="12"
                 sm="12"
                 md="2">
            <quiz-select ref="quizSelect"
                v-model="editItem.next_quiz_id"
                model-prop="id"
                label="Next Quiz"
                :level="editItem.current_level"
                :init-id="editItem.next_quiz_id"
            />
          </v-col>
          <v-col cols="12"
                 sm="12"
                 md="3">
            <v-select
                :items="levels"
                label="Current Level"
                v-model="editItem.current_level"
            ></v-select>
          </v-col>
          <v-col cols="12"
                 sm="12"
                 md="3">
            <v-text-field
                label="Total Points"
                v-model="editItem.total_points"
                suffix="XP"
            ></v-text-field>
          </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   sm="12"
                   md="3">
              <v-select
                  :items="levels"
                  label="Initial Level"
                  v-model="editItem.initial_level"
              ></v-select>
            </v-col>

            <v-col cols="12"
                   sm="12"
                   md="3">
              <v-select
                  :items="levels"
                  label="Highest Level Reached"
                  v-model="this.editItem.highest_level"
              ></v-select>
            </v-col>
          </v-row>
      </v-form>
<!--      </v-card>-->
    </v-col>
  </v-row>
  </div>
</template>

<script>
import _ from 'lodash'
import UserTree from "@/components/tree/UserTree";
import {EventBus} from "@/eventbus";
import QuizSelect from "@/components/QuizSelect";
export default {

  name: "StudentEditor",
  components: {QuizSelect},
  data() {
    return {
      originalItem: null,
      editItem: null,
      loading: true,
      user_id: null,
      levels: ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve']
    }
  },
  created() {
    // this.loadStudentData()
  },
  methods: {
    edit() {

    },

    fetchTeachers() {

    },

    fetchClasses() {

    },

    async load(user_id) {
      this.user_id = user_id
      this.originalItem = null
      this.editItem = null
      this.teachers = []
      this.classes = []
      return this.loadStudentData();
    },

    async save() {
      if (_.isNil(this.editItem)) {
        return false
      }
      let url = '/student/' + this.editItem.id
      const payload = _.pick(this.editItem, ['next_quiz_id', 'current_level', 'total_points', 'initial_level', 'highest_level'])
      const {data, status} = await this.axios.post(url, payload)
      if (status !== 200) {
        EventBus.notify(`Error: server returned status: ${status}`, 'error')
      }
      if (data.success === true) {
        if (this.isStudent) {
          await this.$refs.studentEditor.save()
        }
        this.originalItem = this.editItem;
        return true
      } else {
        EventBus.notify(data.message, 'error')
        return false
      }
    },

    async loadStudentData() {
        this.loading = true;
        const {data} = await this.axios.get(`/user/${this.user_id}/student`)
        if (data.items.length > 0) {
          this.originalItem = data.items[0];
          this.originalItem.type = 'student';
          this.originalItem.user_id = this.user_id
          this.editItem = _.cloneDeep(this.originalItem);
          this.editItem.age = this.editItem.dob ? Math.floor((new Date() - new Date(this.editItem.dob)) / 1000 / 60 / 60 / 24 / 365 ): 'UNKNOWN';
        }
        this.loading = false;
        return this.editItem
    }
  }
}
</script>

<style scoped>

</style>
