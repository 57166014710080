<template>
  <div>
  <v-subheader>Teacher Info
  </v-subheader>
  <v-row class="left">
    <v-col cols="12">
<!--      <v-card elevation="1" class="ma-2">-->
<!--      <v-card-title>Student Info</v-card-title>-->
      <v-skeleton-loader v-if="loading"
          class="'mx-auto pa-3 dark" :boilerplate="false" elevation="0"
          type="list-item-two-line, list-item-three-line, actions"
      ></v-skeleton-loader>
      <v-form v-if="!loading" class="pa-4">
        <v-row class="left">
          <v-col cols="12"
                 sm="6"
                 md="6">
            <v-text-field
                v-model="editItem.id"
                label="Teacher ID"
                disabled
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
<!--      </v-card>-->
    </v-col>
  </v-row>
  </div>
</template>

<script>
import _ from 'lodash'
import UserTree from "@/components/tree/UserTree";
export default {

  name: "TeacherEditor",
  components: {},
  data() {
    return {
      originalItem: null,
      editItem: {},
      loading: true,
      user_id: null,
    }
  },
  created() {
    // this.loadStudentData()
  },
  methods: {
    edit() {

    },

    fetchTeachers() {

    },

    fetchClasses() {

    },

    async load(user_id) {
      this.user_id = user_id
      this.originalItem = null
      this.editItem = {}
      this.students = []
      this.classes = []
      return this.loadTeacherData();
    },

    async loadTeacherData() {
        this.loading = true;
        const {data} = await this.axios.get(`/user/${this.user_id}/teacher`)
        if (data.items.length > 0) {
          this.originalItem = data.items[0];
          this.originalItem.type = 'teacher';
          this.originalItem.user_id = this.user_id
          this.editItem = _.cloneDeep(this.originalItem) || {};
        }
        this.loading = false;
        return this.editItem
    }
  }
}
</script>

<style scoped>

</style>
